import SquareImageLeftTitle from "src/components/articledisplays/SquareImageLeftTitle";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from 'src/lib/styles/css';
import { GroupedLayoutObject, IVerticalArticleList, ThemeValues } from "src/lib/types/IForsidenV2";

//#region [Props]
type VerticalArticleListProps = {
    block: GroupedLayoutObject<IVerticalArticleList>;
    className?: string
};
//#endregion

//#region [Component]
export default function VerticalArticleList({ block, className }: VerticalArticleListProps) {
    return <section className={className}>
        <SListHeader>
            <h2>{block.label}</h2>
        </SListHeader>
        <SList $themeValue={block.themes} $showSeparators={block.showSeparators}>
            {block.articles?.map((doc) => <SquareImageLeftTitle key={doc._id} document={doc} themeValue={block.themes} />)}
        </SList>
    </section>;
}
//#endregion

//#region [Styles]
const SListHeader = styled.header`
    color: var(--textcolor);
    margin-top: 1.5em;
    margin-bottom: 1em;

    h2 {
        font-size: ${theme.fonts.tittelS.size};
        @media ${theme.mq.desktop} {
            font-size: ${theme.fonts.tittelM.size};
        }
    }

`;

type SListProps = {
    $themeValue?: ThemeValues;
    $showSeparators?: boolean;
}

function getThemeBgCss(th?: ThemeValues): string {
    switch (th) {
        case "light":
            return "var(--TEXTCOLOR_OVERRIDE, var(--textcolor))";
        case "lightGradient":
            return theme.palette.gradient1;
        case "gold":
            return theme.palette.gullHovedfarge;
        default:
            return "var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor))";
    }
}

const SList = styled.div<SListProps>`
    border-radius: 8px;
    padding: 12px;

    background: ${props => getThemeBgCss(props.$themeValue)};

    display: flex;
    flex-direction: column;

    @media ${theme.mq.desktop} {
        padding: 20px;
    }

    ${props => props.$showSeparators ? css`
        gap: 30px;
        @media ${theme.mq.desktop} {
            gap: 42px;
        }
        & > article {
            position: relative;

            &:not(:last-child):after {
                content: "";
                display: block;
                height: 3px;
                width: calc(100% - 12px);
                left: 6px;
                background-color: ${(props.$themeValue ?? "dark") !== "dark" ? css`
                    var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttoncolor));
                ` : css`
                    var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
                `};
                position: absolute;
                bottom: -17px;
                border-radius: 2px;

                @media ${theme.mq.desktop} {
                    bottom: -21.5px;

                }
            }
        }
    `: css`
        gap: 15px;
        @media ${theme.mq.desktop} {
            gap: 20px;
        }
    `}



`;

//#endregion