"use client"
import { useMediaQuery } from '@react-hooks-library/core';
import Ad from 'src/components/ads/Ad';
import theme from 'src/components/themes/DefaultTheme';
import { getCookie, setCookie } from "cookies-next";
import { useAdContext } from 'src/lib/contexts/AdContext';
import styled, { css } from 'src/lib/styles/css';
import { useCallback, useEffect, useState } from 'react';

const TARGET_ID = "FW_bottomSticky";

//#region [Props]
type StickyBottomAdProps = {
	className?: string;
    isPackshot?: boolean;
};
//#endregion

//#region [Component]
export default function StickyBottomAd({ className, isPackshot = false }: StickyBottomAdProps) {
	const [canHideAd, setCanHideAd] = useState(false);
	const [showAd, setShowAd] = useState(false);
	const isMqMatch = useMediaQuery(theme.mq.mobile);
	const adContext = useAdContext();

	useEffect(() => {
		if (adContext.noMatchedAds?.includes(TARGET_ID)) {
			setShowAd(false);

		}
	}, [adContext.noMatchedAds]);

	useEffect(() => {
		const cookieValue = getCookie("fwhba", { path: '/', sameSite: 'strict' })
		if (cookieValue === "true") {
			return;
		}
		setShowAd(isMqMatch);
		if (isMqMatch) {
			const timerHandle = setTimeout(() => {
				setCanHideAd(true);
			}, 3000);

			return () => {
				clearTimeout(timerHandle);
			}
		}
	}, [isMqMatch]);

	const hideClickHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		if (canHideAd) {
			setCookie("fwhba", "true", { path: '/', maxAge: 604800, sameSite: 'strict' }); // maxAge: 7 days
			setShowAd(false);
		}
	}, [canHideAd]);

	if (!showAd) {
		return null;
	}

	return <SStickyBottomAd className={className} $isPackshot={isPackshot} aria-label="Annonse">
		<SHideButton aria-label="Skjul annonse" onClick={hideClickHandler}>
			Annonse <SCloseSvg $canHide={canHideAd} />
		</SHideButton>
		<SAd targetId={TARGET_ID} $isPackshot={isPackshot} canHide={false} />
	</SStickyBottomAd>;
}
//#endregion

//#region [Component] Custom Close Svg
type CloseSvgProps = {
	className?: string;
};

function CloseSvg({className}: CloseSvgProps) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" className={className}>
		<g transform="translate(-1125.169 -629.169)">
			<path d="M4.1,4.1a14,14,0,1,1,0,19.8A14,14,0,0,1,4.1,4.1Z" transform="translate(1125.169 629.169)" fill="#352761" />
			<g transform="translate(1138.904 633.408) rotate(45)">
				<g transform="translate(6.171)" fill="none" stroke="#59b7f1" strokeWidth="2">
					<rect width="2.057" height="14.399" rx="1.028" stroke="none" />
					<rect x="1" y="1" width="0.057" height="12.399" rx="0.028" fill="none" />
				</g>
				<g transform="translate(0 8.228) rotate(-90)" fill="none" stroke="#59b7f1" strokeWidth="2">
					<rect width="2.057" height="14.399" rx="1.028" stroke="none" />
					<rect x="1" y="1" width="0.057" height="12.399" rx="0.028" fill="none" />
				</g>
			</g>
		</g>
	</svg>
	);

}
//#endregion

//#region [Styles]
type SAdProps = {
    $isPackshot: boolean;
}

const SStickyBottomAd = styled.div<SAdProps>`
	width: 100%;
	position: fixed;
	bottom: 1px;
	z-index: 1000;
	max-width: 100vw;
	border-top: 2px solid var(--buttoncolor);
	background-color: settings.$lillaMørkHovedfarge;
	height: 18.75vw;

    ${props => props.$isPackshot ? css`
        height: auto;
        max-width: 40vw;
        border-top: none;
        right: 0;
        background-color: transparent;
    `: ""}
`;

type SHideAdProps = {
	$canHide?: boolean;
}

const SHideButton = styled.button`
	padding: 0;
	margin: 0;
	display: block;
	width: 93px;
	height: 24px;
	padding: 0;
	border: none;
	background: var(--buttoncolor);
	position: absolute;
	top: -24px;
	right: 0;
	color: var(--buttontextcolor);
	z-index: 1;
	font-size: 12px;
	border-top-left-radius: 10px;
	text-align: left;
	padding-left: 12px;

`;

const SAd = styled(Ad)<SAdProps>`
	&::after {
		content: none !important;
        display: none !important;
	}

	.adHeading {
		display: none;
	}

	height: 18.75vw;

    ${props => props.$isPackshot ? css`
        height: auto;
        max-width: 100%;
    `: ""}
`;

const SCloseSvg = styled(CloseSvg)<SHideAdProps>`
	position: absolute;
	display: block;
	width: 28px;
	height: 28px;
	right: 0;
	top: -2px;
	opacity: 0;
	transition: opacity 0.5s;

	${props => props.$canHide ? css`
		opacity: 1;
	`: ""}
`;
//#endregion